import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import LinkButton from "../components/pohrby/LinkButton";
import CallButton from "../components/pohrby/CallButton";
import OverenaFirma from "../components/pohrby/OverenaFirma";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Balon from "../assets/img/balon.svg";
import Coin from "../assets/img/coin.svg";
import Heart from "../assets/img/heart.svg";
import Internet from "../assets/img/internet.svg";
import SupportPanel from "../components/SupportPanel";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import Koncese from "../assets/img/koncese.svg";
import Ilustrace2 from "../assets/img/ilustracePohreb2.svg";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import JumbotronV2 from "../components/brandingV2/JumbotronV2";
import CallButtonV2 from "../components/brandingV2/CallButtonV2";
import LinkButtonV2 from "../components/brandingV2/LinkButtonV2";
import RecenzeV2 from "../components/brandingV2/RecenzeV2";
import Intro from "../components/brandingV2/Intro";
import Services from "../components/brandingV2/Services";
import FeaturesV2 from "../components/brandingV2/FeaturesV2";
import Nonprofit from "../components/brandingV2/Nonprofit";
import RegionsV2 from "../components/brandingV2/RegionsV2";
import OneReview from "../components/brandingV2/OneReview";
import BlogPreview from "../components/brandingV2/BlogPreview";
import EndSupport from "../components/brandingV2/EndSupport";


SwiperCore.use([Navigation]);

const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Cena obsahuje vyzvednutí zesnulého kdekoliv v daném městě, úpravu těla, domluvenou formu pohřbu, převezení do krematoria, samotný žeh a následné uložení do dočasné urny nebo jiné, kterou si vyberete. V rámci balíčku Péče+ (<a href="/pohrebni-sluzba-praha/">Praha</a> a <a href="/pohrebni-sluzba-brno/">Brno</a>) vám doručíme <a href="/poradna/kremacni-popel/">urnu</a> do vlastních rukou kamkoliv po regionu a jeho blízkém okolí. V ceně této služby je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a>, digitální pozůstalost, tisk a doručení parte a samozřejmě i možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu. S poplatky navíc můžete počítat u některých regionů v případě: akutního výjezdu nebo pokud má zesnulý více než 100kg.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.<br/><br/>Pokud jsme pro vašeho blízkého jeli nebo pojedeme k vám domů, bude k celkové částce připočítán poplatek 1.990 Kč (v Praze a Ostravě 3.490 Kč), který pokrývá pohotovostní služby zaměstnanců zajišťujících akutní vyzvednutí.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];

const PohrbyInner = styled.div`
  .leftJumbo {
    max-width: 750px !important;
    width: calc(100% - 270px);

    @media (max-width: 899px) {
      width: calc(100%);
    }

    h1 {
      margin-top: 0 !important;

      @media (max-width: 1005px) {
        font-size: 41px !important;
      }

      @media (max-width: 650px) {
        font-size: 35px !important;
      }

      @media (max-width: 520px) {
        font-size: 32px !important;
      }
    }

    .buttons {
      min-width: 700px;
     
      @media (max-width: 899px) {
        min-width: 0px;
        width: 100%;
        display: block !important;
      }

      div {
        @media (max-width: 1005px) {
          display: inline-block;
        }

        @media (max-width: 430px) {
          width: 100%;
        }

        a {
          min-width: 193px;

          @media (max-width: 1100px) {
            min-width: 0;
            white-space: nowrap;
          }

          @media (max-width: 1005px) {
            min-width: 193px;
          }

          @media (max-width: 660px) {
            margin-right: 20px;
          }

          @media (max-width: 430px) {
            width: calc(100% - 48px);
            margin-right: 0px;
          }
        }
      }
    }

    .nowrapReview {
      font-family: Visuelt-Medium;
      white-space: nowrap;

      @media (max-width: 385px) {
        white-space: pre-wrap;
      }
    }

    .rating p {
      @media (max-width: 400px) {
        font-size: 16px;
      }
    }
  }
  
  

  .jumboImage {
    @media (max-width: 1180px) {
      width: 220px !important;
      height: 304px !important;
    }

    @media (max-width: 899px) {
      width: 320px !important;
      height: 204px !important;
      margin-bottom: 10px;
      margin-top: -10px;
    }

    @media (max-width: 430px) {
      width: 250px !important;
      height: 170px !important;
      margin-bottom: 0px;
      margin-top: -20px;
    }
  }
`;






const Pohrby = ({ data }) => {
  const isPlus470 = useMediaQuery({
    query: '(min-width: 470px)'
  });


  const isLess1050 = useMediaQuery({
    query: '(max-width: 1050px)'
  });

  

  return (
    <Layout V2>
      <SEO
        title="Goodbye.cz - Empatická pohřební služba (24/7) | Online závěti"
        customTitle
        description="Důstojné pohřební služby (Praha, Brno, celá ČR) a Digitální Závěti. Pohřeb s obřadem, kremace bez obřadu. Vše naplánujte z domova za transparentní ceny."
        image="/ogImage.png"
      />
      
      <StructuredHodnoceni />
      
      <PohrbyInner>

        <JumbotronV2
          noSplatky
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          h1={"Empatická pohřební služba"}
          hideSecondHeading
          rychleOdkazy
          p={<>Věříme, že si každý člověk zaslouží jedinečné a důstojné rozloučení. Měníme způsob, jakým se rodiny připravují a řeší odchod svých blízkých. S důrazem na osobní přístup, bez přebytečného stresu a nákladů.<br/><br/>Ať už plánujete pohřeb, chcete ho předplatit nebo píšete závěť - náš empatický tým odborníků vás vším provede a bude vám po celou dobu oporou.</>}
          reviewDesc={<>Nejlépe hodnocená pohřební služba za 2025 <span className="nowrapReview">(4,95/5 průměrné hodnocení)</span></>}
          eko
          breadscrum={
            <Breadscrum
              visible={false}
              stranky={[
                {name: "Domů", url: "/"},
              ]}
            />
          }
          customCta={[
            <LinkButtonV2 to="/pohrebni-sluzba/" arrow text="Naplánovat pohřeb" />,
            <LinkButtonV2 to="/predplaceny-pohreb/" arrow light text="Předplatit pohřeb" />,
            <LinkButtonV2 to="/zaveti/" arrow light text="Napsat závěť" />,
          ]}
        />

        <RecenzeV2
          sklonovaneMesto={""}
        />

        <Intro 
          introH2={"V čem jsme jiní než ostatní pohřební služby?"}
          eko
          introText={<>
            Řešení úmrtí blízkého je možná ta nejtěžší situace v našem životě. Plná stresu, zmatku, smutku a jen málokdo se na ni předem připraví. Sami jsme se několikrát setkali s neempatickým jednáním tradičních pohřebních služeb, uspěchanými a pochmurnými obřady — vždy za neúměrnou cenu. To jsme se rozhodli změnit.
            <br/><br/>
            Založili jsme Goodbye s důrazem na osobní přístup a transparentnost. Nemusíte nikam chodit. S rodinou u nás všechny kroky vyřešíte přes internet. Konečnou cenu uvidíte rovnou. Náš tým odborníků vám následně vše vysvětlí a provede vás jakoukoliv situací, se kterou se právě potýkáte. Ať už se jedná o organizaci nebo předplacení pohřbu, sepsání závěti nebo poskytnutí podpory pozůstalým.
          </>}
        />

        <Services />

        <FeaturesV2
          customH2="Jdeme příkladem ostatním společnostem v oboru"
          desc="Měníme způsob, jakým češi přistupují ke smrti. Zakládáme si proto na zodpovědném a empatickém přístupu ve všem, co děláme."
          text1="Bezkonkureční kvalita, certifikace, ověření partneři a podpora známých českých investorů. Jsme společnost, které můžete věřit."
          text2="Na rozdíl od běžných potřebních služeb je naše cena konečná a obsahuje kompletně vše, co budete potřebovat."
          text3="Náš tým je tu pro vás 24 hodin denně, 7 dní v týdnu. Ať už potřebujete cokoliv, vždy se na nás můžete obrátit."
          heading1="Služby, kterým můžete věřit"
          heading2="Pouze transparentní ceny"
          heading3="Vše spolu vyřešíme po internetu"
          ilustrace1={Koncese}
          ilustrace2={Ilustrace2}
          ilustrace3={Internet}
        />

        <Nonprofit />

        <RegionsV2 />

        <OneReview
          text={<>Byli jsme se službami velice spokojeni: rychlost, profesionalita, ochota a vstřícnost. <span>Tento přístup byl pro nás v této těžké situaci skutečnou pomocí.</span></>}
          img={data.recenzeSample.childImageSharp.fluid}
          imgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Hana V."}
          zakaznikKdo={<>Zákaznice <Link to="/pohrebni-sluzba-praha/">pohřební služby Praha</Link></>}
        />

        <BlogPreview />

        <EndSupport customText="Chápeme, že řešení či příprava na odchod blízkého jsou jedny z nejnáročnějších situací v životě člověka. Zavolejte nám a jsme připraveni vaši situaci okamžitě řešit, krok po kroku vás vést a být vám oporou." />

        <FAQ data={faqData}/>

      </PohrbyInner>
    </Layout>
  );
}

export const query = graphql`
  query {
    jumboObrazek: file(relativePath: { eq: "homepageJumboPic.png" }) {
      childImageSharp {
        fixed(width: 287) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jumboObrazekMobile: file(relativePath: { eq: "homepageJumboPicSmall.png" }) {
      childImageSharp {
        fixed(width: 287) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice1Small.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

export default Pohrby;


