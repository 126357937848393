import React, { useEffect, useState} from "react";
import styled from "styled-components";
import { navigate, Link } from "gatsby"
import { useForm } from "react-hook-form";
import axios from 'axios';
import EmailDotaznikPotvrzeni from "../../../components/emails/EmailDotaznikPotvrzeni";
import CheckNe from "../../../assets/img/checkNe.svg";
import CheckJo from "../../../assets/img/checkJo.svg";
import FeatureCheck from "../../../assets/img/featureCheck.svg";
import { renderToString } from 'react-dom/server';
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";
import GoogleLogo from "../../../assets/img/google.svg";
import FirmyLogo from "../../../assets/img/firmyLogo.png";
import Hvezdy from "../../../assets/img/hodnoceniKremace.svg";
import { useCookies } from "react-cookie";

const KonecInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 799px) {
    display: block;
  }

  .gdpr {
    margin: 0 !important;
    margin-top: 15px !important;
    color: #949494 !important;

    a {
      color: #949494;
    }
  }

  .konecLeft {
    max-width: 460px;
    width: calc(100% - 630px);

    @media (max-width: 1150px) {
      width: calc(100% - 420px);
      max-width: none;
    }

    @media (max-width: 799px) {
      width: calc(100%);
    }
  }

  .konecRight {
    width: 530px;
    background: #FFFFFF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 5px;
    padding: 30px;

    @media (max-width: 1150px) {
      width: 320px;
    }

    @media (max-width: 799px) {
      width: calc(100% - 60px);
      margin-top: 30px;
    }

    @media (max-width: 350px) {
      width: calc(100% - 40px);
      padding: 20px;
    }
    
    .sites {
      .site {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .logo {
          max-width: 119px;
          max-height: 31px;
          margin-right: 15px;
        }
      }
    }

    ul {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #D8D8D8;

      li {
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          margin-right: 20px
        }

        div {
          h4 {
            font-family: Visuelt-Bold;
            font-size: 18px;
            color: #000000;
            margin-bottom: 9px;
          }

          p {
            margin: 0 !important;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 25px;
          }
        }
      }
    }
  }
`;

const Formular = styled.div`
  form {
    label {
      margin-bottom: 25px;
      display: block;

      span {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }

      input {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 100%;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        padding: 15px;
        width: calc(100% - 30px);
      }
    }

    .odeslat {
      width: 100%;
      max-width: 320px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #1D3A5B;
      border-radius: 3px;
      border: 0;
      padding: 18px 0;
      cursor: pointer;
      margin-top: 40px;
      display: block;

      &:hover {
        background: #081C33;
        cursor: pointer;
      }
    }

    .errorMsg {
      color: #F83939;
      font-family: Visuelt-Regular;
      margin-top: -15px;
      margin-bottom: 15px;
      display: block;
    }

    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:focus {
        .fakeCheckbox {
          border: 2px solid #243A58;
        }
      }

      input {
        display: none;

        &:focus {
          & + .fakeCheckbox {
            border: 2px solid #243A58;
          }
        }
      }

      .fakeCheckbox {
        background: #FFFFFF;
        background-image: url(${CheckNe});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input:checked + .fakeCheckbox {
        background: #243A58;
        background-image: url(${CheckJo});
        border: 2px solid #243A58;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
      }

      p {
        width: calc(100% - 50px);
        margin: 0 !important;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
      }
    }
  }
`;


let Konec = ({userData, updateData, login}) => {
	const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm(); //watch
  const onSubmit = data => handleForm(data);





  const handleForm = (r) => {

    const vysledek = [Object.assign({r}, userData), {
      client: r.email
    }];

    setIsLoading(true);
    
    console.log(vysledek);


    // nastavení správné backend url

    let backendUrl = "";

    if (process.env.NODE_ENV === 'development') {
      backendUrl = 'http://localhost:1337'
    }

    if (process.env.NODE_ENV === 'production') {
      backendUrl = 'https://goodbye-backend.herokuapp.com'
    }


    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then(res => {
      const jwt = res.data.jwt;

      axios.post(`${backendUrl}/offers/newAccount`, { vysledek }, {
        headers: {
          "Authorization": `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
      })
      .then(res => {

        if (res.status === 200) {
          // tady na /prehled jit

          
          // uložit cookies

          login(res.data);
          // navigate("/nabidka/prehled/");


          setIsLoading(false);
        }

        else {
          alert("Stala se bohužel nějaká technická chyba. Ozvěte se nám prosím na číslo 315 558 136.");
        }
      });
    });
  }



  return (
    <DotaznikLayout phase={2} progress={95}>
      <h1>V dalším kroku uvídíte vlastní cenovou nabídku...</h1>
      <p>Na základě vašich informací jsme pro vás vytvořili vlastní cenovou nabídku pohřbu. Tu si v následujícím kroce můžete dál upravit (vybrat urnu, květiny, rakev...).<br/>Bez vašeho svolení vás nebudeme kontaktovat a nic vám nabízet.</p>
    
      <KonecInner>
        <div className="konecLeft">
          <Formular>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                <span>Vaše celé jméno</span>
                <input ref={register({ required: true })}  type="text" name="name" />
              </label>
              {errors.name && <span className="errorMsg">Toto pole je povinné</span>}

              <label>
                <span>E-mail</span>
                <input ref={register({ required: true })} type="email" name="email" />
              </label>
              {errors.email && <span className="errorMsg">Toto pole je povinné</span>}

              <label>
                <span>Telefonní číslo</span>
                <input ref={register({ required: true })} type="text" name="phone" />
              </label>
              {errors.phone && <span className="errorMsg">Toto pole je povinné</span>}

              <label className={`checkbox`}>
                <input ref={register()} name="zadneVolani" type="checkbox" />
                <div className="fakeCheckbox"></div>
                <p>Souhlasím s tím, že mi můžete zavolat (nepovinné)</p>
              </label>
              
              <input className="odeslat" type="submit" value={isLoading ? "Načítání..." : "Pokračovat"} />
              <p className="gdpr">Pokračováním souhlasíte se <Link to="/parte/gdpr/">zpracovaním dat a GDPR</Link></p>
            </form>
          </Formular>
        </div>

        <div className="konecRight">
          <ul>
            <li>
              <img src={FeatureCheck} alt="" />
              <div>
                <h4>Transparentní</h4>
                <p>Všechny ceny jsou konečné, žádné skryté poplatky.</p>
              </div>
            </li>
            <li>
              <img src={FeatureCheck} alt="" />
              <div>
                <h4>Osobní přístup</h4>
                <p>Kdykoliv nám můžete zavolat. Vše je možné vám přizpůsobit.</p>
              </div>
            </li>
            <li>
              <img src={FeatureCheck} alt="" />
              <div>
                <h4>100% nezávazné</h4>
                <p>Pokud nebudete s nabídkou spokojeni, nikdo vás nebude uhánět.</p>
              </div>
            </li>
          </ul>

          <div className="sites">
            <div className="site">
              <img className="logo" src={GoogleLogo} alt="Google Logo" />
              <img className="stars" src={Hvezdy} alt="" />
            </div>
            <div className="site">
              <img className="logo" src={FirmyLogo} alt="Firmy.cz Logo" />
              <img className="stars" src={Hvezdy} alt="" />
            </div>
          </div>
        </div>
      </KonecInner>
        
      <Link to="/nabidka/dotaznik/pohreb/" className="zpet">← Zpět</Link>
      
    </DotaznikLayout>
  );
};


export default Konec;